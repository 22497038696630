// @flow strict
import React from 'react';
import styles from './Pendulum.module.scss';


const Pendulum = () => {
  return (
    <div className={styles['pendulum']}>
      <div className={styles['pendulum__piece']}></div>
      <div className={styles['pendulum__piece']}></div>
      <div className={styles['pendulum__piece']}></div>
      <div className={styles['pendulum__piece']}></div>
      <div className={styles['pendulum__piece']}></div>
    </div>
  );
};

export default Pendulum;