// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Menu from './Menu';
import useDarkMode from "use-dark-mode";
import Pendulum from './Pendulum';
import styles from './Navbar.module.scss';
import { useSiteMetadata } from '../../hooks';

const Navbar = () => {
  const { menu } = useSiteMetadata();
  const darkMode = useDarkMode(false);
  return (
    <div className={styles['navbar']}>
      <div className={styles['navbar__left']}>
        <Link to="/" className={styles['navbar__left-author']}>
          <Pendulum />
        </Link>
      </div>
      <div className={styles['navbar__right']}>
        <Menu menu={menu} />
        <div onClick={darkMode.toggle}>
          <div className={styles['navbar__right-darkmode']}/>
        </div>
      </div>
    </div>
  );
};

export default Navbar;